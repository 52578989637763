export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            법무법인 이공
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                법인소개
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                이공이 지향하는 가치
              </a>
            </li>
            <li className="dropdown">
              <a href="#team" className="dropdown-toggle page-scroll">구성원<span className="caret hidden-xs"></span></a>
              <ul className="dropdown-menu hidden-xs">
              {props.data
            ? props.data.map((d, i) => (
              <li key={`${d.name}-${i}`} onClick={() => { props.setModalTitle(`${d.name} ${d.job}`); props.setModalData(d) }} style={{ pointerEvents: 'all', cursor: 'pointer'}}>
                <a href="#team" style={{ color: '#333', backgroundColor: 'transparent' }}>{`${d.name} ${d.job}`}</a>
              </li>
            ))
            : <li><a href="#team">'loading'</a></li>}
              </ul>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
