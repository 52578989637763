export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/yigong_logo_08.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>법인소개</h2>
              {props.data ? props.data.paragraph.map((d, i) =>
                <p key={`${d}-${i}`}>{d}</p>
              ) : <p>loading...</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
