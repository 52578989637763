import { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons'
import ReCAPTCHA from "react-google-recaptcha";

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const recaptchaRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()

    const token = recaptchaRef.current.getValue()

    console.log({ name, email, message, token })
    emailjs
      .send(
        'service_i5j7eqk',
        'template_vnuugai',
        { name, email, message, 'g-recaptcha-response': token },
        'bPUdCPrxaEQ_Loa2Z',
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          alert('문의가 성공적으로 전송되었습니다.')
        },
        (error) => {
          console.log(error.text)
          alert('문의 전송에 실패했습니다. 사무실로 직접 연락 부탁드립니다.')
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>문의하기</h2>
                <p>
                  법무법인 이공에 방문해주셔서 감사합니다. 변호사와의 상담을 원하신다면 예약 문의 부탁드립니다.
                </p>
              </div>
              <form name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='성함'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='문의내용'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div className='col-md-6 col-12' style={{ justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LccnGkqAAAAANOqdk2ZxsbqRDPbGfhqRwQnwsYt"
                    />
                  </div>
                  <div className='col-md-6 col-12' style={{ justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>
                    <button type='submit' className='btn btn-custom btn-lg' >
                      문의하기
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>연락처</h3>
              <p>
                <span>
                  <FontAwesomeIcon icon={faMapMarker} />{' '} 본사무소
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <FontAwesomeIcon icon={faPhone} /> {' '} 전화
                </span>
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <FontAwesomeIcon icon={faEnvelope} /> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div >
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 법무법인 이공
          </p>
        </div>
      </div>
    </div >
  )
}
