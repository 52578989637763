import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

ReactModal.setAppElement('#root')

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation data={landingPageData.Team} setModalData={setModalData} setModalTitle={setModalTitle} />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Team data={landingPageData.Team} setModalData={setModalData} setModalTitle={setModalTitle} />
      <Contact data={landingPageData.Contact} />
      <ReactModal
        isOpen={modalTitle.length > 0}
        onRequestClose={() => { setModalTitle('') }}
        style={{
          overlay: {
            zIndex: 1050,
          },
          content: {
            background: '#fff',
            outline: 0,
            minWidth: '50vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            borderRadius: '4px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <header className="modal-header">
          <h2 id="demo-two-title" className="modal-title">
            {modalTitle}
          </h2>
        </header>
        <div className="modal-body">
          {modalData.expertise ? <>
            <h3>주요 분야</h3>
            <ul>
              {modalData.expertise.map((v, i) =>
                <li key={i}>
                  {v}
                </li>
              )}
            </ul>
          </> : null}
          {modalData.education ? <>
            <h3>학력</h3>
            <ul>
              {modalData.education.map((v, i) =>
                <li key={i}>
                  {v}
                </li>
              )}
            </ul>
          </> : null}
          {modalData.career ? <>
            <h3>경력</h3>
            <ul>
              {modalData.career.map((v, i) =>
                <li key={i}>
                  {v}
                </li>
              )}
            </ul>
          </> : null}
          {modalData.activity ? <>
            <h3>활동</h3>
            <ul>
              {modalData.activity.map((v, i) =>
                <li key={i}>
                  {v}
                </li>
              )}
            </ul>
          </> : null}
          {modalData.books ? <>
            <h3>저서</h3>
            <ul>
              {modalData.books.map((v, i) =>
                <li key={i}>
                  {v}
                </li>
              )}
            </ul>
          </>
            : null}
        </div>
        <footer className="modal-footer" style={{ textAlign: 'right' }}>
          <span onClick={() => { setModalTitle('') }} style={{ fontSize: '14px', pointerEvents: 'all', cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faXmark} /> 창 닫기
          </span>
        </footer>
      </ReactModal>
    </div>
  );
};

export default App;
